// import Vue from "vue";
import axios from "axios";
import { getToken } from "@/core/services/jwt/jwt.service";
import router from "@/core/router/router";

/**
 *
 */
const ApiService = {
  init() {
    axios.defaults.baseURL = process.env.AXIOS_DEFAULT_BASE_URL || "https://work.evipstudio.pl";
    // status server
    axios.interceptors.response.use(null, (error) => {
      const { status } = error.response;
      if ((!error.response && navigator.onLine) || status === 500) {
        router.push({ name: "server-offline" });
      }
    });
  },

  setHeader() {
    axios.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  },

  query(resource, params) {
    return axios.get(resource, params).catch((error) => {
      throw new Error(`${error}`);
    });
  },

  /**
   *
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return axios.get(`${resource}/${slug}`).catch((error) => {
      // (error);
      throw new Error(` ${error}`);
    });
  },

  /**
   *
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return axios.post(`${resource}`, params, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  /**
   *
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return axios.put(`${resource}/${slug}`, params);
  },

  /**
   *
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return axios.put(`${resource}`, params);
  },

  /**
   *
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return axios.delete(resource).catch((error) => {
      throw new Error(` ${error}`);
    });
  },
};

export default ApiService;
