import Vue from "vue";
import VueRouter from "vue-router";
import { checkPermission } from "@/core/router/permissionRoleAuth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/views/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Pages/Dashboard/Dashboard.vue"),
      },
      {
        path: "/role",
        name: "role",
        component: () => import("@/views/Pages/Roles/Roles.vue"),
        beforeEnter: (to, from, next, nameRouter = "RoleUser") =>
          checkPermission(to, from, next, nameRouter),
        children: [
          {
            path: "/roles-list",
            name: "RolesList",
            component: () => import("@/views/Pages/Roles/RolesList.vue"),
            meta: { parent: "roles", permissions: "view" },
          },
          {
            path: ":id",
            name: "RoleID",
            component: () => import("@/views/Pages/Roles/Role/Role.vue"),
            meta: { parent: "roles", permissions: "view" },
          },
          {
            path: "edit/:id",
            name: "editRole",
            component: () => import("@/views/Pages/Roles/Role/EditRole/editRole.vue"),
            meta: { parent: "roles", permissions: "edit" },
          },
          {
            path: "edit/:id/form",
            name: "editRoleForm",
            component: () => import("@/views/Pages/Roles/Role/EditRole/editRoleForm.vue"),
            meta: { parent: "roles", permissions: "edit" },
          },
          {
            path: "/role-new",
            name: "newRole",
            component: () => import("@/views/Pages/Roles/Role/NewRole.vue"),
            meta: { parent: "roles", permissions: "create" },
          },
          {
            path: "edit/:id",
            name: "editRole",
            component: () => import("@/views/Pages/Roles/Role/EditRole/editRole.vue"),
            meta: { parent: "roles", permissions: "edit" },
          },
        ],
      },
      {
        path: "/user",
        name: "user",
        component: () => import("@/views/Pages/Users/Users.vue"),
        beforeEnter: (to, from, next, nameRouter = "User") =>
          checkPermission(to, from, next, nameRouter),
        children: [
          {
            path: "/users-list",
            name: "UsersList",
            component: () => import("@/views/Pages/Users/UsersList.vue"),
            meta: { parent: "users", permissions: "view" },
          },
          {
            path: ":id",
            name: "UserID",
            component: () => import("@/views/Pages/Users/User/User.vue"),
            meta: { parent: "users", permissions: "view" },
          },
          {
            path: "/user-new",
            name: "newUser",
            component: () => import("@/views/Pages/Users/User/newUser.vue"),
            meta: { parent: "users", permissions: "create" },
          },
          {
            path: "edit/:id",
            name: "editUser",
            component: () => import("@/views/Pages/Users/User/EditUser/editUser.vue"),
            meta: { parent: "users", permissions: "edit" },
          },
          {
            path: "edit/:id/form",
            name: "editUserForm",
            component: () => import("@/views/Pages/Users/User/EditUser/editUserForm.vue"),
            meta: { parent: "users", permissions: "edit" },
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Pages/Auth/Login.vue"),
  },
  {
    path: "/forgotpassword",
    name: "forgot-password",
    component: () => import("@/views/Pages/Auth/ResetPassword.vue"),
  },
  {
    path: "/offline",
    name: "server-offline",
    component: () => import("@/views/Pages/Error/ServerOffline.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
