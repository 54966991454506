import ApiService from "@/core/services/Api/api.service";

// import JwtService from "@/core/services/jwt.service";

// action types
export const GET_ROLES = "getRoles";
export const GET_ROLE = "getRole";
export const DELETE_ROLE = "deleteRole";
// mutation types
export const SET_ROLES = "setRoles";
export const SET_ROLE = "setRole";

const state = {
  roles: {
    list: [],
    length: 0,
  },
  role: null,
};

const getters = {
  getRoles(state) {
    return state.roles;
  },
  getRole(state) {
    return state.role;
  },
};

const actions = {
  [GET_ROLES](context) {
    return new Promise((resolve, reject) => {
      ApiService.query("/api/role-users")
        .then((resp) => {
          context.commit("setRoles", resp.data);
          resolve(resp);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_ROLE](context, slug) {
    return new Promise((resolve, reject) => {
      ApiService.query(`/api/role-users/${slug}`)
        .then((resp) => {
          context.commit("setRole", resp.data);
          resolve(resp);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [DELETE_ROLE](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/api/role-users/${id}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_ROLES](state, roles) {
    state.roles = roles;
  },
  [SET_ROLE](state, role) {
    state.role = role;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
