import axios from "axios";
import ApiService from "@/core/services/Api/api.service";
import { getToken, saveToken, destroyToken } from "@/core/services/jwt/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const REQUEST_PASSWORD = "requestPassword";
export const CHANGE_PASSWORD = "changePassword";
export const VERIFY_TOKEN = "verifyToken";
export const PERMISSIONS = "permissions";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_VERIFY_TOKEN = "setVerifyTokenRequestPassword";
export const SET_PERMISSIONS = "setPermissions";
export const SET_CURRENT_USER = "setCurrentUser";

const state = {
  errors: null,
  user: window.localStorage.getItem("currentUser-evip") || "User",
  isAuthenticated: !!getToken(),
  permissions: null,
  isVerifyTokenRequestPassword: true,
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  isVerifyTokenRequestPassword(state) {
    return state.isVerifyTokenRequestPassword;
  },
  getPermissionsList(state) {
    let data = {};
    for (const i in state.permissions) {
      data[i] = Object.keys(state.permissions[i].actions);
    }
    return data;
  },

  getPermissions(state) {
    return state.permissions;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    context.commit(PURGE_AUTH);
    return new Promise((resolve, reject) => {
      ApiService.post("/api/login_check", credentials)
        .then((resp) => {
          context.commit(SET_AUTH, resp.data);
          context.commit(SET_CURRENT_USER, credentials.username);
          resolve(resp);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
          reject(response);
        });
    });
  },

  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [PERMISSIONS](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/api/user-permissions/get-simple-list", credentials)
        .then((resp) => {
          context.commit(SET_PERMISSIONS, resp.data);
          window.localStorage.setItem("permission", JSON.stringify(resp.data));
          resolve(resp);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
          reject(response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (getToken()) {
      ApiService.setHeader();
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [VERIFY_TOKEN](context, token) {
    return new Promise((resolve) => {
      ApiService.post("/api/users/validate-password-token", {
        email: token.email,
        token: token.token,
      })
        .then(({ data }) => {
          context.commit(SET_VERIFY_TOKEN, data.isTokenValid);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [REQUEST_PASSWORD](context, email) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/users/password-token?email=${email}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [CHANGE_PASSWORD](context, changePasswordParams) {
    return new Promise((resolve, reject) => {
      axios
        .patch("/api/users/password", {
          email: changePasswordParams.email,
          token: changePasswordParams.token,
          plainPassword: changePasswordParams.plainPassword,
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    saveToken(state.user.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    destroyToken();
  },
  [SET_VERIFY_TOKEN](state, token) {
    state.isVerifyTokenRequestPassword = token;
  },
  [SET_CURRENT_USER](state, username) {
    state.user = username;
    window.localStorage.setItem("currentUser-evip", username);
  },
  [SET_PERMISSIONS](state, permission) {
    let data = {};
    for (let i of permission) {
      data[i.entityName] = i;
    }
    state.permissions = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
