/* eslint-disable */
export const checkPermission = (to, from, next, nameRouter) => {
  const permissions = JSON.parse(window.localStorage.getItem("permission"));
  let permissionsList = {};
  for (let i of permissions) {
    permissionsList[i.entityName] = i;
  }
  // if user has all permission
  if (Object.values(permissionsList[nameRouter].actions).filter((i) => i.length > 1).length === 0)
    next();
  // if (data[nameRouter].actions[permission].length === 0) {
  //   next();
  // } else if (Object.keys(data[nameRouter].actions).includes(permission)) {
  //   if (!to.params.id) {
  //     next();
  //     return;
  //   }
  //   to.params.id && data[nameRouter].actions[permission].includes(Number(to.params.id))
  //     ? next()
  //     : next("dashboard");
  // } else {
  //   next("dashboard");
  //   this.toastError("ERROR", "BRAK UPRAWNIEŃ");
  //   return false;
  // }
};
