import ApiService from "@/core/services/Api/api.service";

export const NEW_ROLE = "newRole";
export const GET_CONFIGURATOR_ROLE = "getConfiguratorRole";
export const GET_ENTITY_TREE = "getEntityTree";

export const SET_CONFIGURATOR_ROLE = "setConfiguratorRole";
export const SET_ENTITY_TREE = "setEntityTree";
export const DELETE_ROLE_PERMISSION = "deleteRolePermission";
export const EDIT_ROLE = "editRole";
export const ROLE_CONFIGURATOR = "roleConfigurator";

const state = {
  configuratorRole: {},
  rolePermissions: [],
};

const getters = {
  getConfiguratorRole(state) {
    return state.configuratorRole;
  },
  getEntityTree(state) {
    return state.rolePermissions;
  },
};

const actions = {
  [NEW_ROLE](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/api/role-users", credentials)
        .then((resp) => {
          resolve(resp);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_CONFIGURATOR_ROLE](context) {
    return new Promise((resolve, reject) => {
      ApiService.query("/api/entities-tree/get-tree")
        .then((resp) => {
          context.commit(SET_CONFIGURATOR_ROLE, resp.data);
          resolve(resp);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  [GET_ENTITY_TREE](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.query(`/api/role-users/${id}/permissions-tree`)
        .then((resp) => {
          context.commit("setEntityTree", resp.data);
          resolve(resp);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [EDIT_ROLE](context, editRoleParams) {
    return new Promise((resolve, reject) => {
      ApiService.update("/api/role-users", editRoleParams.id, editRoleParams)
        .then((resp) => {
          resolve(resp);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [ROLE_CONFIGURATOR](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/api/role-permissions", credentials)
        .then((resp) => {
          resolve(resp);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [DELETE_ROLE_PERMISSION](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/api/role-permissions/${id}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(({ resp }) => {
          reject(resp);
        });
    });
  },
};

const mutations = {
  [SET_CONFIGURATOR_ROLE](state, configurator) {
    state.configuratorRole = configurator;
  },
  [SET_ENTITY_TREE](state, rolePermissions) {
    state.rolePermissions = rolePermissions;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
