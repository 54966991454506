export const locale = {
  MENU: { DASHBOARD: "Dashboard", ROLES: "Role dostępowe" },
  USER: { PROFILE: "Profil", SIGNOUT: "Wyloguj" },
  FORM: {
    LOGIN: "Login",
    FORGOT_PASSWORD: "Request password",
    CANCEL: "Cancel",
    CHANGE_PASSWORD: "Change password",
    PASSWORD: "Password",
    PASSWORD_REPEAT: "Repeat password",
    SELECTED: "Selected",
  },
  TABLE: {
    LABEL: "Label",
    NAME: "Name",
    DURATION: "Duration",
    IS_ACTIVE: "Active",
    IS_ACTIVE_FALSE: "Inactive",
    IS_ACTIVE_TRUE: "Active",
    CREATED_AT: "Created at ",
    UPDATED_AT: "Updated at",
    INFINITE: "Infinite",
    INSTANT: "Instant",
    SHOW: "Show",
    EDIT: "Edit",
    DELETE: "Delete",
  },
  ALERT: {
    TOKEN_PASSWORD_EXPIRED: "Password reset link has expired Please generate a new one.",
    PRESS_ENTER_SEARCH: "Press ENTER to search",
  },
};
