import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pl from "vuetify/lib/locale/pl";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {},
  },
  icons: {
    iconfont: "fa",
  },
  lang: {
    locales: { pl },
    current: "pl",
  },
});
