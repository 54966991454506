import ApiService from "@/core/services/Api/api.service";

// action types
export const GET_USERS = "getUsers";
export const NEW_USER = "newUser";
export const DELETE_USER = "deleteUser";

// mutation types
export const SET_USERS = "setUsers";

// getters types

const state = {
  users: {
    list: [],
    length: 0,
  },
};

const getters = {
  getUsers(state) {
    return state.users;
  },
  getHeaders(state) {
    return state.headers;
  },
};

const actions = {
  [GET_USERS](context) {
    return new Promise((resolve, reject) => {
      ApiService.query("/api/users")
        .then((resp) => {
          context.commit(SET_USERS, resp.data);
          resolve(resp);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [NEW_USER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/api/users", credentials)
        .then((resp) => {
          resolve(resp);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [DELETE_USER](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/api/users/${id}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_USERS](state, users) {
    state.users = users;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
