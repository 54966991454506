import Vue from "vue";

Vue.mixin({
  methods: {
    mapArray(array, mapBy) {
      return array.map((i) => i[mapBy]).filter((i) => i);
    },
    toastSuccess(message, titleMessage) {
      this.$bvToast.toast(message, {
        title: titleMessage,
        variant: "success",
      });
    },
    toastError(message, titleMessage) {
      this.$bvToast.toast(message, {
        title: titleMessage,
        variant: "danger",
      });
    },
    isObjectEmpty(obj) {
      return !Object.values(obj).some((v) => v);
    },
  },
});

// filters

const cutText = (text, value) => `${text + 2 + value}cutText`;
Vue.filter("cutText", cutText);
