import Vue from "vue";
import App from "@/App.vue";
import router from "./core/router/router";
import store from "@/core/store";
import "@/assets/scss/index.scss";

// VUE SERVICES  //
import ApiService from "@/core/services/Api/api.service";
// VUE PLUGINS  //
import i18n from "@/core/plugins/vue-i18n";
import "@/core/plugins/inline-svg";
import { VERIFY_AUTH } from "@/core/store/auth.module";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/bootstrap";
import "@/core/plugins/vue-table";
import "@/core/plugins/multiselect";
import "@/core/plugins/globalFunc";
import "@/core/plugins/DatePicker";
import "@/core/plugins/Vuelidate";
import "@/core/plugins/ResetInput";
import "@/core/plugins/VueOffline";

ApiService.init();
router.beforeEach((to, from, next) => {
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
