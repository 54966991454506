import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth.module";
import roles from "./Roles/roles.module";
import role from "./Roles/role.module";
import users from "./Users/users.module";
import user from "./Users/user.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    roles,
    role,
    users,
    user,
  },
});
