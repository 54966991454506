import Vue from "vue";
/* eslint-disable */
let component = null;
const ResetInput = (Vue) => {
  return {
    bind(el, binding, vnode) {
      const expression = vnode.data.model.expression.split(".");
      const [object, name] = expression;

      if (binding.modifiers.hover) {
        el.addEventListener("mouseenter", (e) => {
          e.preventDefault();
          const inputReset = el.querySelector(".reset-input");
          if (inputReset && vnode.context[object][name]) {
            inputReset.classList.remove("d-none");
          }
        });
        el.addEventListener("mouseleave", () => {
          const inputReset = el.querySelector(".reset-input");
          if (inputReset && vnode.context[object][name]) {
            inputReset.classList.add("d-none");
          }
        });
      }
      let clickHandler = (e) => {
        vnode.context[object][name] = "";
      };

      let ResetComponent = Vue.extend({
        render(h) {
          return h("div", {
            on: { click: (e) => clickHandler(e) },
            domProps: { innerHTML: '<i class="fas fa-times"></i>' },
            class: { "reset-input": true, "d-none": true },
            style: { right: binding.modifiers.right && "-20px" },
          });
        },
      });

      component = ResetComponent;
      let span = document.createElement("div");
      el.prepend(span);
      component = new ResetComponent().$mount(span);
    },
    update(el, binding, vnode) {
      const input = vnode.elm.querySelector(".reset-input");
      const expression = vnode.data.model.expression.split(".");
      const [object, name] = expression;
      if (!vnode.context[object][name] && input) {
        input.classList.add("d-none");
      } else if (vnode.context[object][name] && input) {
        input.classList.remove("d-none");
      }
    },
  };
};

Vue.directive("reset-input", ResetInput(Vue));
