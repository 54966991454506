import ApiService from "@/core/services/Api/api.service";

// ACTIONS
export const GET_USER = "getUser";
export const EDIT_USER = "editUser";
export const DELETE_USER_PERMISSION = "deleteUserPermission";
export const GET_USER_PERMISSIONS = "getUserPermissions";
export const USER_CONFIGURATOR = "userConfigurator";

// SETTERS
export const SET_USER = "set_user"; // w autoryzacji jest setter który nazywa się 'setUser", gdy go użyjemy nadpiszemy autoryzację
export const SET_USER_PERMISSIONS = "setUserPermissions";

// GETTERS
const state = {
  userView: {},
  userPermissions: [],
};

const actions = {
  [GET_USER](context, slug) {
    return new Promise((resolve, reject) => {
      ApiService.query(`/api/users/${slug}`)
        .then((resp) => {
          resolve(resp);
          context.commit("set_user", resp.data);
        })
        .catch((resp) => {
          reject(resp);
        });
    });
  },
  [EDIT_USER](context, editUserParams) {
    return new Promise((resolve, reject) => {
      ApiService.update("/api/users", editUserParams.id, {
        firstName: editUserParams.firstName,
        lastName: editUserParams.lastName,
        phone: editUserParams.phone,
        roles: editUserParams.roles,
        email: editUserParams.email,
        enabled: editUserParams.enabled,
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GET_USER_PERMISSIONS](context, slug) {
    return new Promise((resolve, reject) => {
      ApiService.query(`/api/users/${slug}/permissions-tree`)
        .then((resp) => {
          context.commit(SET_USER_PERMISSIONS, resp.data);
          resolve(resp);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [USER_CONFIGURATOR](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/api/user-permissions", credentials)
        .then((resp) => {
          resolve(resp);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [DELETE_USER_PERMISSION](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/api/user-permissions/${id}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(({ resp }) => {
          reject(resp);
        });
    });
  },
};

const mutations = {
  [SET_USER](state, user) {
    state.userView = user;
  },
  [SET_USER_PERMISSIONS](state, userPermissions) {
    if (userPermissions) {
      state.userPermissions = userPermissions;
    } else {
      state.userPermissions = "none";
    }
  },
};

const getters = {
  getUser(state) {
    return state.userView;
  },
  getUserPermissions(state) {
    return state.userPermissions;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
