export const locale = {
  MENU: { DASHBOARD: "Dashboard", ROLES: "Role dostępowe", USERS: "Użytkownicy" },

  FORM: {
    LOGIN: "Zaloguj",
    FORGOT_PASSWORD: "Przypomnij hasło",
    CANCEL: "Anuluj",
    CHANGE_PASSWORD: "Zmień hasło",
    PASSWORD: "Hasło",
    PASSWORD_REPEAT: "Powtórz hasło",
    SELECTED: "Wybrany",
  },
  TABLE: {
    LABEL: "Nazwa",
    NAME: "Nazwa techniczna",
    DURATION: "Czas",
    EMAIL: "Email",
    FIRST_NAME: "Imię",
    LAST_NAME: "Nazwisko",
    CONFIRMED: "Zatwierdzono",
    ENABLED: "Aktywny",
    confirmed: "Aktywny",
    IS_ACTIVE: "Aktywny",
    IS_ACTIVE_FALSE: "Nieaktywny",
    IS_ACTIVE_TRUE: "Aktywny",
    CREATED_AT: "Utworzono",
    UPDATED_AT: "Aktualizacja",
    INFINITE: "Nieskończoność",
    INSTANT_SELECT: "Ilość dni",
    INFINITE_SELECT: "Nieskończoność",
    RANGE_SELECT: "Zakres",
    INSTANT: "Ilość dni - {expires_after} dni",
    RANGE: "Zakres - od {expires_on} do {begins_on}",
    SHOW: "Pokaż",
    EDIT: "Edytuj",
    DELETE: "Usuń",
    CLEAR: "Wyczyść filtrowanie",
    true: "Aktywny",
    false: "Nieaktywny",
    CONFIRMED_TRUE: "Zatwierdzony",
    CONFIRMED_FALSE: "Niezatwierdzony",
    enabled_TRUE: "Aktywny",
    enabled_FALSE: "Nieaktywny",
    ENABLED_TRUE: "Aktwyny",
    ENABLED_FALSE: "Nieaktwyny",
    confirmed_TRUE: "Zweryfikowany",
    confirmed_FALSE: "Niezweryfikowany",
  },
  ALERT: {
    TOKEN_PASSWORD_EXPIRED: "Password reset link has expired Please generate a new one.",
    PRESS_ENTER_SEARCH: "Naciśnij ENTER aby wyszukać",
  },
  ROLE: {
    id: "Id",
    name: "Nazwa",
    label: "Nazwa techniczna",
    isActive: "Status",
    duration: "Czas",
    createdAt: "Stworzono",
    updatedAt: "Zaktualizowano",
    instant: "Zakres",
    false: "Nieaktywny",
    true: "Aktywny",
    ADD: "Dodaj rolę",
    email: "E-mail",
    firstName: "Imię",
    lastName: "Nazwisko",
    enabled: "Aktywny",
    confirmed: "Zweryfikowany",
  },
  USER: {
    PROFILE: "Profil",
    ADD: "Dodaj użytkownika",
    id: "Id",
    email: "E-mail",
    phone: "Telefon",
    roles: "Rola",
    firstName: "Imię",
    lastName: "Nazwisko",
    enabled: "Aktywowano",
    confirmed: "Zweryfikowano",
    duration: "Czas",
    createdAt: "Stworzono",
    updatedAt: "Zaktualizowano",
    instant: "Zakres",
    false: "Nieaktywny",
    true: "Aktywny",
  },
  VALID: {
    required: "{name} jest wymagany",
  },
  BACK: "Wróć",
  ADD: "Dodaj",
  INFINITE: "Nieskończoność",
  RANGE: "Zakres",
  INSTANT: "Ilość dni",
  CLOSE: "Zamknij",
  SAVE: "Zapisz",
  OPEN: "Otwórz",
  DELETE: "Usuń",
  EDIT: "Edytuj",
  FORGOT_PASSWORD: "Przypomnij hasło",
  LOGIN: "Zaloguj",
  PASSWORD: "Hasło",
  SEARCH: "Szukaj",
  NO_RESULT: "Brak wyników",
  SURE: "Jesteś pewny/a ?",
  SUCCESS: "Sukces!",
  CLEAR: "Wyczyść",
  PASSWORD_PLAIN: "Powtórz hasło",
  FIRST_NAME: "Imię",
  LAST_NAME: "Nazwisko",
};
